<script setup lang="ts">
import { flatten } from 'flat'
import { get } from 'lodash'
import { nanoid } from 'nanoid'
import { useStorage } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import { VButton, VDropdownImproved, VIcon, VSkeletonBar } from '../components'
import { useSearch } from '../composables/use-search'
import { useSlots } from '../composables/use-slots'
import { useSort } from '../composables/use-sort'
import { search } from '../utils/deep-search'
import { export_csv, sortCsvItems } from '../utils/export-csv'
import { Column, calculate_aggregate, format, parse, unflattenKey } from '../utils/v-table'
import Big from 'big.js'
import { Money } from '../utils/money'

const props = defineProps<{
  columns: Column[]
  items: any[]
  name: string
  skeleton?: boolean
  slots?: string[]
  sub_item_key?: string
  label?: string
  no_data_message?: string
  expand?: boolean
}>()

const { n, t } = useExtendedI18n()
const { clearQuery, query } = useSearch()
const { definedSlot } = useSlots(props.slots)

const flattenedItems = computed(() => props.items.map((item) => flatten(item)))
const preparedItems = computed(() => search(flattenedItems.value, query.value))
const is_subrows_visible = ref({})
const skeletonArray: number[] = Array.from({ length: 5 })

///////////////////////////////////////////////////////////////////////////////
// Columns
///////////////////////////////////////////////////////////////////////////////

const constantKeys = ['actions']
const route = useRoute()
const columns = computed(() =>
  props.columns.filter(
    (column) =>
      column.is_accessible === undefined ||
      column.is_accessible ||
      (constantKeys.includes(column.key) && column.is_accessible),
  ),
)
const storageKey = `v-table:${route.name as string}:selected-column-keys`
const defaultKeys = columns.value.filter((column) => column.is_visible).map((column) => column.key)
const selectedColumnKeys = ref(defaultKeys) // useStorage<string[]>(props.name, defaultKeys)
const selectedColumns = computed(() => columns.value.filter((column) => selectedColumnKeys.value.includes(column.key)))

// options for the dropdown
const transformedOptions = computed(() =>
  columns.value
    .map((column) => ({
      disabled: column.key === 'actions',
      label: column.name,
      value: column.key,
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
)

watch(columns, (new_columns) => {
  if (new_columns) {
    const keys = new_columns.filter((column) => column.is_visible).map((column) => column.key)
    selectedColumnKeys.value = selectedColumnKeys.value
      .concat(keys)
      .filter((key, index, array) => array.indexOf(key) === index)
  }
})

///////////////////////////////////////////////////////////////////////////////
// Sorting
///////////////////////////////////////////////////////////////////////////////

const defaultSortKey = columns.value.filter((column) => column.sorted)[0]?.key || columns.value[0].key
const { sortKey, sortOrder, sort } = useSort({ key: defaultSortKey })

const sortedItems = computed(() => {
  const order = sortOrder.value === 'asc' ? 1 : -1
  const column = columns.value.find((column) => column.key === sortKey.value)

  if (!preparedItems.value) return []
  is_subrows_visible.value = !props.expand
    ? {}
    : preparedItems.value.reduce((acc, item, i) => {
        acc[i] = true
        return acc
      }, {})
  return [...preparedItems.value].sort((a, b) => {
    if (['currency'].includes(column.type)) {
      const aValue = new Big(unflattenKey(a, sortKey.value).amount)
      const bValue = new Big(unflattenKey(b, sortKey.value).amount)

      if (aValue.lt(bValue)) return -1 * order
      if (aValue.gt(bValue)) return 1 * order
    } else {
      const aValue = parse(get(a, sortKey.value), column.type)
      const bValue = parse(get(b, sortKey.value), column.type)

      if (aValue < bValue) return -1 * order
      if (aValue > bValue) return 1 * order
    }
    return 0
  })
})

///////////////////////////////////////////////////////////////////////////////
// Aggregating
///////////////////////////////////////////////////////////////////////////////

const hasAggregate = computed(() => columns.value.some((column) => column.aggregate))

const aggregateType = (column: Column) => {
  switch (column.aggregate) {
    case 'count':
      return 'number'
    default:
      return column.type
  }
}

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const createKey = () => nanoid(8)

const last = (index: number, array: any[]) => index === array.length - 1

const isExpandable = (item: any) => unflattenKey(item, props.sub_item_key)?.length

const empty_data = computed(() => sortedItems?.value.length === 0 && !props.skeleton)

const types = {
  boolean: 'bool',
  currency: '$',
  date: null,
  number: '#',
  string: null,
}

const exportCSV = (name, flattenedItems, columns) => {
  let data = []
  const order = 1
  const column = columns[0]
  const items = sortCsvItems(flattenedItems, columns)

  items.forEach((item) => {
    data.push(item)
    if (props.sub_item_key) {
      const sub_items = unflattenKey(item, props.sub_item_key).map((sub_item) => flatten(sub_item))
      data.push(sortCsvItems(sub_items, columns))
    }
  })
  data = data.flat()
  console.log(data)
  return export_csv(name, data, columns, n, false)
}
</script>

<template>
  <div class="relative">
    <div
      v-if="items.length === 0 && no_data_message && !skeleton"
      class="absolute left-1/2 top-1/2 z-50 h-full w-full -translate-x-1/2 -translate-y-1/2 bg-white/50 p-6 py-12 text-center text-sm font-medium text-gray-700 backdrop-blur-sm"
    >
      <div class="mx-auto w-1/2">{{ no_data_message }}</div>
    </div>
    <div class="relative rounded-t-lg border border-gray-200">
      <div class="flex items-center space-x-1.5">
        <div class="relative flex-grow">
          <input
            type="text"
            class="relative z-40 w-full rounded-none rounded-tl-lg border-b-0 border-l-0 border-r-0 border-t-0 border-gray-200 bg-transparent text-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
            :placeholder="`Search by anything`"
            v-model="query"
          />
          <div class="pointer-events-none absolute inset-y-0 right-0 z-50 flex items-center pr-3">
            <button
              @click="clearQuery"
              class="curosr-pointer pointer-events-auto inline-block rounded-lg bg-gray-100 p-1"
              v-show="query"
            >
              <VIcon name="x" class="h-3 w-3 text-gray-400" />
            </button>
          </div>
        </div>
        <div class="block">
          <VButton size="xs" @click="exportCSV(name, flattenedItems, columns)">
            <div class="flex items-center space-x-1.5">
              <VIcon name="corner-up-right" class="my-px block h-3.5 w-3.5" />
              <div>Export</div>
            </div>
          </VButton>
        </div>
        <div class="z-20 block pr-1.5">
          <VDropdownImproved
            v-model="selectedColumnKeys"
            :options="transformedOptions"
            aligned="right"
            :hideDisabled="true"
          >
            <VButton size="xs">
              <div class="flex items-center space-x-1.5">
                <VIcon name="settings" class="my-px block h-3.5 w-3.5" />
                <div>Edit columns</div>
              </div>
            </VButton>
          </VDropdownImproved>
        </div>
      </div>
    </div>
    <div class="rounded-b-lg border border-t-0 border-gray-200">
      <div
        id="vtable"
        class="relative w-full overflow-x-auto"
        :class="empty_data ? 'overflow-x-hidden' : 'overflow-x-auto'"
      >
        <div
          v-show="empty_data"
          class="absolute left-1/2 top-[60%] -translate-x-1/2 -translate-y-1/2 p-6 py-12 text-center text-sm"
        >
          Nothing to display
        </div>
        <div v-show="selectedColumns.length === 0" class="p-6 py-12 text-center text-sm">No columns selected</div>
        <table>
          <thead>
            <tr>
              <th
                v-for="column in selectedColumns"
                :key="column.key"
                :class="{
                  'fixed-column': column.fixed,
                  'text-center': column.align === 'center',
                  'text-left': column.align === 'left',
                  'text-right': column.align === 'right',
                }"
                @click="sort(column.key)"
              >
                <span class="inline-block font-semibold">{{ column.name }}</span>
                <VIcon
                  :class="['-mt-[2px] ml-1 inline-block h-4 text-gray-500', sortOrder === 'asc' ? '' : 'rotate-180']"
                  name="arrow_narrow_up"
                  v-show="sortKey === column.key"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="skeleton">
              <tr v-for="(n, index) in skeletonArray" :key="n">
                <td
                  v-for="(column, column_index) in selectedColumns"
                  :key="column.key"
                  :class="{
                    'border-b-0': last(index, skeletonArray) && !hasAggregate,
                    'fixed-column': column.fixed,
                    'rounded-bl-lg': last(index, skeletonArray) && column_index === 0 && !hasAggregate,
                    'rounded-br-lg': last(index, skeletonArray) && last(column_index, selectedColumns) && !hasAggregate,
                    'text-center': column.align === 'center',
                    'text-left': column.align === 'left',
                    'text-right': column.align === 'right',
                  }"
                  class="border-b group-hover:first:bg-gray-50"
                >
                  <VSkeletonBar />
                </td>
              </tr>
            </template>
            <template v-else-if="empty_data && selectedColumns.length > 0">
              <tr>
                <td :colspan="selectedColumns.length" class="border-b-0">
                  <div class="p-6 py-14 text-center text-sm"></div>
                </td>
              </tr>
            </template>
            <template v-else v-for="(item, index) in sortedItems" :key="createKey()">
              <tr class="group">
                <td
                  v-for="(column, column_index) in selectedColumns"
                  :key="column.key"
                  :class="{
                    'border-b-0': last(index, sortedItems) && !hasAggregate && !is_subrows_visible[index],
                    'border-gray-100': is_subrows_visible[index],
                    'fixed-column': column.fixed,
                    'rounded-bl-lg first:rounded-bl-lg':
                      last(index, sortedItems) && column_index === 0 && !hasAggregate && !is_subrows_visible[index],
                    'rounded-br-lg':
                      last(index, sortedItems) &&
                      last(column_index, selectedColumns) &&
                      !hasAggregate &&
                      !is_subrows_visible[index],
                    'text-center': column.align === 'center',
                    'text-left': column.align === 'left',
                    'text-right': column.align === 'right',
                  }"
                  class="border-b group-hover:bg-gray-50 group-hover:first:bg-gray-50"
                >
                  <div class="inline-flex items-center space-x-2">
                    <VButton
                      v-if="column_index === 0 && sub_item_key"
                      @click="() => (is_subrows_visible[index] = !is_subrows_visible[index])"
                      class="block rounded-[4px] px-[1px] py-[1px]"
                      size="xs"
                    >
                      <VIcon
                        class="h-3 w-3"
                        :class="{ '-rotate-90': !is_subrows_visible[index] }"
                        name="chevron_down"
                      />
                    </VButton>
                    <div v-if="definedSlot(column.key)" class="whitespace-normal">
                      <slot :name="column.key" :column="column" :item="item"> </slot>
                    </div>
                    <template v-else>
                      <div v-if="['currency'].includes(column.type)">
                        {{ n(unflattenKey(item, column.key), column.type) }}
                      </div>
                      <div v-else>
                        {{ format(item[column.key], column.type) }}
                      </div>
                    </template>
                  </div>
                </td>
              </tr>
              <tr
                class="group"
                v-show="is_subrows_visible[index]"
                v-if="isExpandable(item)"
                v-for="(sub_item, expandableIndex) in unflattenKey(item, sub_item_key)"
              >
                <td
                  v-for="(column, columnIndex) in selectedColumns"
                  :key="column.key"
                  :class="{
                    '!bg-gray-50': true,
                    'border-b-0':
                      last(index, sortedItems) &&
                      last(expandableIndex, unflattenKey(item, sub_item_key)) &&
                      !hasAggregate,
                    'border-gray-100': true,
                    'border-gray-200': last(expandableIndex, unflattenKey(item, sub_item_key)),
                    'fixed-column': column.fixed,
                    'text-center': column.align === 'center',
                    'text-left': column.align === 'left',
                    'text-right': column.align === 'right',
                  }"
                  class="border-b group-hover:bg-gray-50 group-hover:first:bg-gray-50"
                >
                  <span
                    :class="{ 'pl-[22px]': isExpandable(item) && columnIndex === 0 }"
                    class="block whitespace-normal break-normal"
                  >
                    <template v-if="definedSlot(column.key)">
                      <slot :name="`subgroup.${column.key}`" :column="column" :item="sub_item"> </slot>
                    </template>
                    <div v-else-if="['currency'].includes(column.type)">
                      {{ n(unflattenKey(flatten(sub_item), column.key), column.type) }}
                    </div>
                    <div v-else>
                      {{ format(flatten(sub_item)[column.key], column.type) }}
                    </div>
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot v-if="hasAggregate && !empty_data">
            <tr class="group">
              <td
                v-for="column in selectedColumns"
                :key="column.key"
                :class="{
                  'fixed-column first:bg-white': column.fixed,
                  'text-center': column.align === 'center',
                  'text-left': column.align === 'left',
                  'text-right': column.align === 'right',
                }"
              >
                <div v-if="skeleton && column.aggregate">
                  <VSkeletonBar />
                </div>
                <div v-else>
                  <template v-if="definedSlot(column.key)">
                    <slot :name="`tfoot.${column.key}`" :column="column" :items="sortedItems"> </slot>
                  </template>
                  <div v-else-if="['currency'].includes(column.type)">
                    {{ n(calculate_aggregate(sortedItems, column) as Money, column.type, null, true) }}
                  </div>
                  <div v-else-if="['percent'].includes(column.type)">
                    {{ format(calculate_aggregate(sortedItems, column), aggregateType(column)) }}
                  </div>
                  <div v-else>{{ format(calculate_aggregate(sortedItems, column), aggregateType(column)) }}</div>
                </div>
                <div class="text-[10px] text-gray-500">
                  {{ typeof column.aggregate === 'function' ? 'Call / Distribution' : column.aggregate }}
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
table {
  @apply w-full border-separate border-spacing-0;
}

thead {
  @apply sticky top-0 text-[12px] font-light uppercase text-gray-700;
}

th {
  @apply m-0 min-w-[100px] cursor-pointer whitespace-nowrap border-x-0 border-b border-gray-200 bg-white/50 px-3 py-3  text-xs backdrop-blur first:bg-white sm:min-w-[10px];
}

@media (min-width: 640px) {
  th.fixed-column {
    @apply first:sticky first:left-0 first:z-10 first:border-r;
  }

  td.fixed-column {
    @apply first:sticky first:left-0 first:z-[1] first:min-w-[320px] first:max-w-[480px] first:border-r first:bg-white;
  }

  tfoot td.fixed-column {
    @apply rounded-bl-lg;
  }
}

tbody {
  @apply text-sm text-gray-700;
}

tbody td {
  @apply whitespace-nowrap px-3 py-3;
}

tfoot {
  @apply text-sm font-medium uppercase tracking-wide;
}

tfoot td {
  @apply whitespace-nowrap border-gray-200 px-3 py-2.5;
}

table tbody td,
table tfoot td {
  border-top: none !important;
}
</style>
